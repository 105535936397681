<script setup>
import { onMounted } from "vue"
import { useRouter } from "vue-router"

const router = useRouter()

onMounted(() => {
  console.log("AuthTest component has been mounted")
  window.sessionStorage.setItem("force_sso_auth", "true")

  router.push({ path: "/" }).catch((error) => {
    console.error("Error during navigation:", error)
  })
})
</script>

<template>
  <div class="box box-outline pad-box">
    <p>Redirecting to Auth</p>
  </div>
</template>

<style scoped>
.info-icon {
  font-size: 2.5em;
  color: #8a73e5;
}
.header {
  font-weight: 700;
}

.parent {
  display: flex;
  align-items: flex-start;
  align-items: center;
  @media (--tablet) {
    flex-direction: row;
    align-items: flex-start;
  }
}
.text-parent {
  flex: 1;
}
</style>
